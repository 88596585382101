<template>
  <div>
    <slot name="header" />
    <slot name="content">
      <b-container>
        <h3 class="h3 text-center">
          <strong>{{  get_config().site.name  }}</strong> è un servizio disponibile sui migliori portali di welfare aziendale.
          <br>
          Contattaci per sapere se è disponibile sul tuo portale welfare.
          <br><br>
          <strong>
            Accedi al tuo portale welfare e prenota la tua fantastica vacanza!
          </strong>
        </h3>
      </b-container>
    </slot>
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  name: 'token-expired',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
.homepage {
}
</style>