<template>
  <div class="homepage">
    <!--booking engine-->
    <section class="section section--booking-engine">
      <div class="section--booking-engine__backdrop">
        <img v-if="filterBrands.length" :src="'assets/images/alpitour/slider/slider-vacanze-vadobay-'+filterBrands[0]+'.jpg'" />
        <img v-else src="assets/images/alpitour/slider/slider-vacanze-vadobay.jpg" />
      </div>
      <div class="section--booking-engine__content">
        <b-container>
          <vb-icon :name="'symbol_vv'" />
          <div>
            <div class="section--booking-engine__heading">
              <h1 class="vb-heading" v-html="'Le vacanze per il tuo welfare'" />
            </div>
            <div class="section--booking-engine__text" v-html="'Prenoti con un click, hai l\'assistenza di un\'agenzia di viaggi'" />
          </div>
        </b-container>
      </div>
      <b-container v-if="is_authenticated()">
        <booking-engine @search="() => { this.$router.push({ name: 'results' }) }"/>
      </b-container>
    </section>

    <section class="section section--loader" v-if="searching">
      <loader size="large" :show_timing_slot="true" />
    </section>

    <token-expired v-if="!is_authenticated()">
      <!--<template v-slot:header />-->
      <!--<template v-slot:content />-->
      <!--<template v-slot:footer />-->
    </token-expired>

    <!--brands-->
    <section class="section">
      <b-container>
        <brands />
      </b-container>
    </section>

    <!--static content section-->
    <section class="section">
      <b-container>
        <b-row>
          <b-col lg="6">
            <div class="section--booking-engine static">
              <div class="section--booking-engine__backdrop">
                <img src="assets/images/alpitour/statics/hp-static-1.jpg" />
              </div>
              <div class="section--booking-engine__content">
                <b-container fluid>
                  <vb-icon :name="'symbol_vv'" />
                  <div class="d-flex flex-column align-items-start">
                    <div class="section--booking-engine__heading">
                      <h2 class="vb-heading d-inline-block" v-html="'Cosa è incluso?'" />
                    </div>
                    <div class="section--booking-engine__text mb-2" v-html="'I pacchetti sono Volo+Hotel'" />
                    <div class="section--booking-engine__text" v-html="'e includono trasferimenti* ed assicurazione'" />
                  </div>
                </b-container>
              </div>   
            </div>
            <p class="text mt-4">
              Scopri i dettagli di ogni pacchetto usando il motore di ricerca qui sopra. Puoi trovare maggiori informazioni sulle condizioni contrattuali ed assicurative nella nostra pagina Condizioni d'uso.
              * I trasferimenti non sono inclusi nei pacchetti per gli Stati Uniti. Per maggiori informazioni contattare l'assistenza.
            </p>
          </b-col>
          <b-col lg="6">
            <div class="section--booking-engine static">
              <div class="section--booking-engine__backdrop">
                <img src="assets/images/alpitour/statics/hp-static-2.jpg" />
              </div>
              <div class="section--booking-engine__content">
                <b-container fluid>
                  <vb-icon :name="'symbol_vv'" />
                  <div class="d-flex flex-column align-items-start">
                    <div class="section--booking-engine__heading">
                      <h2 class="vb-heading d-inline-block" v-html="'Ti aspettiamo in aeroporto'" />
                    </div>
                    <div class="section--booking-engine__text mb-2" v-html="'Alla partenza e all\'arrivo a destinazione'" />
                    <div class="section--booking-engine__text mb-2 hidden" v-html="'#'" />
                  </div>
                </b-container>
              </div>   
            </div>
            <p class="text mt-4">Ti assisteremo in Italia per le formalità di imbarco e all'arrivo a destinazione per gestire il tuo trasferimento in hotel con mezzi riservati. (Valido per tutti i voli charter e di linea charterizzati, chiedi i dettagli alla nostra assistenza).</p>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!--suggestions section-->
    <section class="section" v-if="suggestions">
      <b-container>
        <suggestions :suggestions="suggestions" :autoplay="suggestions_autoplay_speed"/>
      </b-container>
    </section>

    <!--live booking e flyby-->
    <div class="section">
      <b-container>
        <div class="d-flex justify-content-center">
          <vb-icon class="mr-3" :name="'symbol_vv'" />
          <h3 class="vb-heading" v-html="'Cerchi solamente un soggiorno senza volo o solo un volo?'" />
        </div>
        <div class="d-flex justify-content-center mt-5">
          <a href="https://www.vadobay.com/" target="_blank"><img class="px-4" src="assets/images/common/brands/live-booking-payoff.svg" alt="VadoBay livebooking" /></a>
          <a href="https://voli.vadobay.com/" target="_blank"><img class="px-4" src="assets/images/common/brands/fly-by-payoff.svg" alt="VadoBay fly by" /></a>
        </div>
        <p class="text-center mt-5">Cerca i nostri servizi <strong>VadoBay livebooking</strong> e <strong>VadoBay fly by</strong> sul tuo portale welfare.</p>
      </b-container>
    </div>

    <!--value props list-->
    <section class="section">
      <b-container>
        <point-list-01 
          :pl="[
            {
              pl_icon: 'shield',
              pl_title: 'I migliori brand italiani',
              pl_text: 'Alpitour, Francorosso, Bravo, Edenviaggi e tanti altri. Il meglio delle vacanze in tutto il mondo.',
            },
            {
              pl_icon: 'happy_pin',
              pl_title: 'Per il welfare aziendale',
              pl_text: 'Un servizio pensato per un\'utenza aziendale, dal booking online all\'assistenza',
            },
            {
              pl_icon: 'hands',
              pl_title: 'Conferma immediata',
              pl_text: 'Nessuna burocrazia, nessun rimborso e nessun voucher. Prenoti con un click!',
            },
            {
              pl_icon: 'heart',
              pl_title: 'Come in agenzia ma online',
              pl_text: 'Solo su Vacanze by VadoBay trovi un\'assistenza attenta e dedicata anche su webchat e WhatsApp',
            },
          ]"/>
      </b-container>
    </section>

    <!--welfare non sufficiente-->
    <section class="section" v-if="is_authenticated()">
      <image-with-text-cta
          :image_src="'assets/images/alpitour/statics/iStock-1180231917.png'"
          :text_heading="'Welfare non sufficiente?'"
          :text_content="'Se il tuo credito welfare non è sufficiente troviamo insieme una soluzione. <strong>Contattaci!</strong>'"
          :text_cta="[
          {
            cta_text: 'Chat',
            cta_icon: 'chat',
            cta_url: 'javascript:window.zE.activate()',
          },
          {
            cta_text: 'Whatsapp',
            cta_icon: 'whatsapp',
            cta_url: get_config().site.waIntentLink,
          },
          {
            cta_text: 'Email',
            cta_icon: 'email',
            cta_url: 'mailto:'+get_config().site.emailSupport,
          }
        ]"
      />
    </section>

    <!--value props row-->
    <section class="section">
      <b-container>
        <value-proposition
          :vp="[
            {
              vp_image: 'assets/images/alpitour/statics/iStock-1208856002.jpg',
              vp_content: [
                {
                  vp_icon: 'symbol_vv',
                  vp_title: 'Hai bisogno di un consiglio?',
                  vp_text: 'Se cerchi un suggerimento o la soluzione a tue specifiche esigenze, parla con un nostro consulente. <strong><a href='+get_config().site.phoneNumber+'>Chiamaci allo '+get_config().site.phoneNumber+'</a></strong>',
                },
                {
                  vp_icon: 'symbol_vv',
                  vp_title: 'Su di noi puoi contare!',
                  vp_text: 'Solo Vacanze VadoBay ti offre un\'assistenza multicanale, telefonica, webchat, WhatsApp, email, webcall a portata di click e dedicata a gli utenti di welfare aziendale. <strong><a href=javascript:window.zE.activate()>Parla con noi!</a></strong>',
                },
              ]
            },
          ]"/>
      </b-container>
    </section>

  </div>
</template>

<script>
import { mapState } from "vuex"
import TokenExpired from '@components/blocks/token-expired.vue'
import PointList01 from '@library/blocks/point-list-01.vue'
import ValueProposition from '@library/blocks/value-proposition.vue'
import Suggestions from '@alpitour/components/blocks/suggestions.vue'
import Brands from '@alpitour/components/blocks/brands.vue'
import ImageWithTextCta from '@library/blocks//image-with-text-cta.vue'
import { get_config } from "@src/utilities"

export default {
  name: 'homepage',
  components: {
    'token-expired': TokenExpired,
    'point-list-01': PointList01,
    'value-proposition': ValueProposition,
    'brands': Brands,
    'suggestions': Suggestions,
    'image-with-text-cta': ImageWithTextCta
  },
  data() {
    return {
      filterBrands: (get_config()['guiSettings']['filterBrands'])? get_config()['guiSettings']['filterBrands'] : [],
      suggestions: get_config('searchSuggestions', []),
      suggestions_autoplay_speed: get_config('guiSettings.autoplaySpeedSuggestions', 0),
    }
  },
  computed: {
    // global state keys
    ...mapState([
      'searching',
    ]),    
    // module state keys
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.homepage {
  .section {
    z-index: 100;
    &--booking-engine {
      margin-top:0;
      min-height: 320px;
      padding: $grid-gutter-width 0;
      position:relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      &+.text {
        font-size: 0.8125rem;
      }
      .container,
      .container-fluid {
        position:relative;
        z-index: 90;
      }
      @include media-breakpoint-up(md) {
        min-height: auto;
        height: 600px;
        max-height: 60vh;
        padding: $grid-gutter-width*2 0;
      }

      &__backdrop {
        position:absolute;
        width:100%;
        height:100%;
        overflow: hidden;
        top:0;
        bottom:0;
        left:0;
        right:0;
        &::before {
          content:"";
          position:absolute;
          width:100%;
          height:100%;
          background:$black;
          z-index: 10;
          opacity: 0.05;
        }
        img {
          position:absolute;
          width:100%;
          height:100%;
          object-fit:cover;
          object-position: center center;
        }
      }

      &__content {
        display: block;
        width:100%;
        .container,
        .container-fluid {
          display: flex;
          align-items: flex-start;
          &>.vb-icon {
            margin-top:8px;
            margin-right:10px;
          }
        }
      }
            
      &__heading {
        display: block;
        .vb-heading {
          background:$white;
          padding:0 8px 0 12px;
          border-radius: $border-radius;
        }
      }

      &__text {
        display: inline-block;
        background:$white;
        padding:0 8px 0 12px;
        border-radius: $border-radius;
        font-size: 1.25rem;
      }
      
      .booking-engine {
        padding-top:$grid-gutter-width;
        @include media-breakpoint-up(md) {
          padding-top: $grid-gutter-width*2;
        }
      }
      
      &.static {
        height: auto;
        padding: 0;
        .section--booking-engine__backdrop {
          border-radius: $border-radius-lg;
          position: static;
          img {
            position: static;
          }
        }
        .section--booking-engine__content {
          position: absolute;
          width:100%;
          height:100%;
          top: 0;
          display: flex;
          align-items: flex-end;
          padding-bottom: $grid-gutter-width*2;
          .hidden {
            visibility: hidden;
          }
          .container-fluid {
            @include media-breakpoint-down(md) {
              .hidden {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  ::v-deep {

    .image-with-text-cta {
      background: $white;

      &__image img {
        object-position: center 20%;
        width: 1600px;
        right: 0;
        left: 0;
        margin: 0 auto;
      }
    }
  }
}
</style>