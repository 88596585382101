var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suggestions"},[_c('swiper-container',{ref:"swiper-container",attrs:{"init":"false"}},_vm._l((_vm.suggestions),function(suggestion,index){return _c('swiper-slide',{key:_vm._uid+index},[_c('custom-link',{attrs:{"route_name":'results',"wrap":'div',"css_class":'suggestions__pad',"before_navigation":() => {
          _vm.update_store(suggestion)
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('vb-card',{attrs:{"card":{
            image: suggestion.pad.imgSrc,
            title: suggestion.pad.title,
            text: suggestion.pad.text,
          }}})]},proxy:true}],null,true)})],1)}),1),_c('vb-icon',{staticClass:"arrow",attrs:{"name":'angle_left',"size":20,"id":"prev-button"},on:{"click":function($event){return _vm.swiper_instance.swiper.slidePrev()}}},[_vm._v("PREV")]),_c('vb-icon',{staticClass:"arrow",attrs:{"name":'angle_right',"size":20,"id":"next-button"},on:{"click":function($event){return _vm.swiper_instance.swiper.slideNext()}}},[_vm._v("NEXT")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }