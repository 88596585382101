<template>
  <div class="image-with-text-cta">
    <div class="image-with-text-cta__image" v-if="image_src">
      <img :src="image_src"/>
      <div class="image-with-text-cta__text">
        <b-container>
          <vb-icon :name="'symbol_vv'" />
          <div>
            <div class="image-with-text-cta__heading" v-if="text_heading">
              <h1 class="vb-heading" v-html="text_heading" />
            </div>
            <div class="image-with-text-cta__content" v-if="text_content" v-html="text_content" />
            <div class="image-with-text-cta__ctas" v-if="text_cta.length">
              <div class="image-with-text-cta__cta" v-for="(cta,cta_index) in text_cta" v-bind:key="cta_index">
                <a :href="cta.cta_url">
                  <vb-button
                    :vb_options="{
                      text: cta.cta_text,
                      icon: cta.cta_icon,
                      size: 'sm'
                    }"
                  />
                </a>
              </div>
            </div>
          </div>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'image-with-text-cta',
  components: {},
  props: {
    image_src: {
      type: String,
      default: '',
    },
    text_heading: {
      type: String,
      default: '',
    },
    text_content: {
      type: String,
      default: '',
    },
    text_cta: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {

    }
  },
  created() {
  },
  computed: {
  },
  mounted() {
  }
}
</script>

<style lang="scss">
.image-with-text-cta {
  position:relative;
  min-height: 320px;
  @include media-breakpoint-up(md) {
    min-height:500px;
  }
  &__image {
    img {
      position:absolute;
      object-fit: cover;
      height:100%;
      width:100%;
      object-position: top center;
    }
  }
  &__text {
    position:absolute;
    bottom:$grid-gutter-width;
    left:0;
    width:100%;
    .container,
    .container-fluid {
      display: flex;
      align-items: flex-start;
      &>.vb-icon {
        margin-top:8px;
        margin-right:10px;
      }
    }
  }
  &__heading {
    &>* {
      display: inline-block;
    }

    .vb-heading {
      background:$white;
      padding:0 8px 0 12px;
      border-radius: $border-radius;
    }
  }
  &__content {
    &>* {
      display: inline-block;
    }
 
    background:$white;
    padding:0 8px 0 12px;
    border-radius: $border-radius;
    font-size: 1.25rem;
  }
  &__ctas {
    margin-top:8px;
    &>* {
      display: inline-block;
      margin-right:8px;
    }
  }
  &__cta {}
}
</style>