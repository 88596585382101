<template>
  <div class="pl">
    <b-row>
      <b-col sm="6" lg="3" v-for="(item,index) in pl" v-bind:key="_uid+index">
        <div class="pl__item">
          <div class="pl__icon">
            <vb-icon :name="item.pl_icon" :dotted="true" />
          </div>
          <div class="pl__title h6" v-html="item.pl_title" />
          <div class="pl__text" v-html="item.pl_text" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'points-list',
  components: {
  },
  props: {
    pl: {
      type: Array,
      default: () => { return [] }
    }
  },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
.pl {
  background:$white;
  border-radius: $border-radius;
  padding: $grid-gutter-width;
  &__item {
    text-align: center;
    margin:0 auto;
    max-width: 240px;
    padding: $grid-gutter-width/2 0;
  }
  &__icon {
    display: flex;
    align-items: center;
    margin-bottom: $grid-gutter-width;
    .vb-icon {
      margin: auto;
      svg {
        width: auto;
        height: 30px;
      }
    }
  }
  &__title {
    @include font-primary-semibold;
    margin-top:0;
  }
  &__text {
    font-size: 0.8125rem;
    margin-top: 1rem;
  }
  .row {
    justify-content: center;
    @include media-breakpoint-up(lg) {
      justify-content: space-between;
        &>* {
          flex: 1;
          max-width: none;
        }
      .pl__item {
        max-width: 80%;
      }
    }
  }
}
</style>