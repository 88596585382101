<template>
  <div class="brands">
    <div class="brands__logos" v-if="!filterBrands">
      <div v-for="(brand,brand_index) in brands" v-bind:key="_uid+brand_index">
        <img v-if="!get_config()['guiSettings']['filterBrands'] && brand.show_in_homepage" :src="'assets/images/alpitour/liveat-brands/'+brand.logo" :alt="brand.name" />
      </div>
    </div>
    <div class="brands__logos" v-else>
      <div v-for="(brand,brand_index) in brands" v-bind:key="_uid+brand_index">
        <img v-if="filterBrands.includes(brand_index)" :src="'assets/images/alpitour/liveat-brands/'+brand.logo" :alt="brand.name" />
      </div>
    </div>
  </div>
</template>

<script>
import { brands } from '@alpitour/resources'
import {get_config} from "@src/utilities";
export default {
  name: 'brands',
  props: {
  },
  components: {
  },
  data() {
    return {
      filterBrands: (get_config()['guiSettings']['filterBrands'])? get_config()['guiSettings']['filterBrands'] : false,
    }
  },
  created() {
    this.brands = brands
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
.brands {
  text-align:center;
  p {
    font-size: $font-size-sm;//0.8125rem;
    margin-bottom: $grid-gutter-width/2;
    text-transform: uppercase;
    color: $gray-500;
    //@include font-primary-semibold;
  }
  &__logos {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin:0 auto;
    //background: $white;
    border-radius: $border-radius;
    padding: $grid-gutter-width/2 0 0 $grid-gutter-width;
    img,svg {
      height: 25px;
      width: auto;
      margin: 0 $grid-gutter-width $grid-gutter-width/2 0;
    }
  }
}
</style>